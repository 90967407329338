

export default `
query homepageCategories {
\thomepageCategories (pageSize: 16){
\t\titems {
\t\t\tid
\t\t\tuid
\t\t\tname
\t\t\timage
\t\t\tpriority
\t\t\turl_path
\t\t\turl_suffix
\t\t\tposition
\t\t\tlevel
\t\t}

\t\ttotal_count
\t\tpage_info {
\t\t\tcurrent_page
\t\t\tpage_size
\t\t\ttotal_pages
\t\t}
\t}
}
`;
