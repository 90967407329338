


















































































































































































































































































































































import {
  computed,
  defineComponent, onMounted, PropType, ref, useContext, useRouter, watch, useRoute,
} from '@nuxtjs/composition-api';

import {
  SfMegaMenu, SfList, SfImage, SfMenuItem, SfBanner, SfButton, SfModal, SfBar, SfAccordion, SfBadge, SfOverlay,
} from '@storefront-ui/vue';

import SvgImage from '~/components/General/SvgImage.vue';
import {CategoryTree, ProductInterface} from '~/modules/GraphQL/types';
import {useUiHelpers, useUiState, useWishlist} from '~/composables';
import type {ComponentTemplateRef} from '~/types/componentTemplateRef';
import {useCategoryStore} from '~/modules/catalog/category/stores/category';
import {isBooleanObject} from 'util/types';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import {useCart} from '~/modules/checkout/composables/useCart';
import {useUser} from '~/modules/customer/composables/useUser';
import {useWishlistStore} from '~/modules/wishlist/store/wishlistStore';
import {useTopBar} from '../../TopBar/useTopBar';
import {useSidebarLinkGroups} from "~/modules/customer/pages/MyAccount/useSidebarLinkGroups";

export default defineComponent({
  name: 'MobileNavigation',
  components: {
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    HeaderNavigationSubcategories: () => import('~/components/Header/Navigation/HeaderNavigationSubcategories.vue'),
    HeaderNavigationItem,
    SfMegaMenu,
    SfList,
    SfImage,
    SfMenuItem,
    SfBanner,
    SfButton,
    SfModal,
    SfAccordion,
    SfBar,
    SfBadge,
    SfOverlay,
    SvgImage,
    HeaderLogo,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
  },

  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    // currentCategory: {
    //   type: Object as PropType<CategoryTree | null>,
    //   default: () => null,
    // },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      isLoginModalOpen,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const router = useRouter();
    const route = useRoute();
    const {logoutUser} = useSidebarLinkGroups();
    const {getCatLink} = useUiHelpers();
    const lvl1CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2CatRefs = ref<ComponentTemplateRef[]>();
    const lvl2GroupedCatRefs = ref<ComponentTemplateRef[][]>();
    let MobileMenuOpen = false;
    const {isAuthenticated} = useUser();
    const {loadTotalQty: loadCartTotalQty, cart} = useCart();
    const {loadItemsCount: loadWishlistItemsCount} = useWishlist();
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const {hasCurrencyToSelect, hasStoresToSelect} = useTopBar();


    const currentCategory = ref<CategoryTree | null>(null);

    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    const {app} = useContext();
    let lvl0CatFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({name: 'customer'}));
      } else {
        toggleLoginModal();
      }
    };
    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    let blaaa = ref<any>([]);
    blaaa = {children: props.categoryTree, include_in_menu: 1};
    const childrenCat = blaaa.children;

    let newMenu = {
      'Produse': blaaa,
      'Promotii': {},
      'Outlet': {},
      'Servicii Eurovial Lighting': {},
      'Panouri fotovoltaice': {}
    };
    const {isDesktop} = app.$device;
    const allCategories = ref<any>([]);

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };
    const toggleMobileMenuNew = () => {
      MobileMenuOpen = true;
    };
    const closeMenu = () => {
      toggleMobileMenu();

    };

    let isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });
    // const hideSearch = () => {
    //   if (isSearchOpen) {
    //     isSearchOpen = ref(false);
    //     if (document) {
    //       document.body.classList.remove('no-scroll');
    //     }
    //   }
    // };

    watch(route, () => {
      isSearchOpen.value = false;
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    });
    return {
      getCatLink,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0CatRefs,
      lvl1CatRefs,
      logoutUser,
      navRight,
      navLeft,
      hasFocus,
      onMouseEnter,
      newMenu,
      isMobileMenuOpen,
      toggleMobileMenu,
      isDesktop,
      MobileMenuOpen,
      toggleMobileMenuNew,
      isLoginModalOpen,
      allCategories,
      blaaa,
      childrenCat,
      closeMenu,
      isSearchOpen,
      wishlistHasProducts,
      wishlistItemsQty,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      isAuthenticated,
      hasCurrencyToSelect,
      handleAccountClick,
      toggleCartSidebar,
      toggleWishlistSidebar,
      hasStoresToSelect,
      productSearchResults,
    };
  },
});
