/* eslint-disable  */

export default {
  "About us": "Despre noi",
  "Account": "Cont",
  "Add new address": "Adaugă o nouă adresă",
  "Add to compare": "Adaugă pentru a compara",
  "Add to Wishlist": "Wishlist",
  "Additional Information": "Informații suplimentare",
  "All Orders": "Toate comenzile",
  "Already have an account": "Ai deja cont?",
  "Order Number": "Numar Comanda",
  "Allow order notifications": "Permite notificări în cazul comenzilor",
  "Apply": "Aplică",
  "Applied Coupon": "Cupon aplicat",
  "Attention!": "Atenţie!",
  "Back to home": "Înapoi acasă",
  "Back to homepage": "Înapoi la pagina de start",
  "Billing": "Facturare",
  "Billing address": "Adresă de facturare:",
  "Brand": "Marca",
  "Cancel": "Anulare",
  "Cancel order": "Anulează comanda",
  "Cart": "Coș de cumpărături",
  "Cart summary": "Sumar comandă",
  "Categories": "Categorii",
  "Change": "Editare",
  "Change password your account": "Dacă doriți să schimbați parola pentru a vă accesa contul, introduceți următoarele informații",
  "Clear all": "Curăță tot",
  "Color": "Culoare",
  "Commercial information": "și sunteți de acord să primiți informații comerciale personalizate pentru acest Brand prin e-mail",
  "Contact details updated": "Menține-ți adresele și datele de contact actualizate.",
  "Contact information": "Informații de contact",
  "Contact us": "Contactaţi-ne",
  "Continue": "Continuă",
  "Continue to billing": "Continuați cu facturarea",
  "Continue to payment": "Continuați cu plata",
  "Continue to shipping": "Continuați cu expedierea",
  "Continue with Facebook": "Continuă cu Facebook",
  "Continue with Google": "Continuă cu Google",
  "Cookies Policy": "Politica de cookie-uri",
  "Create an account": "Creați-vă un cont",
  "Customer Reviews": "Recenziile clienților",
  "Customer service": "Serviciu clienți",
  "Dashboard": "Panou de control",
  "Date": "Data comenzii: ",
  "Default Billing Address": "Adresa de facturare implicită",
  "Default Shipping Address": "Adresa de livrare implicita",
  "Delete": "Șterge",
  "Departments": "Departamente",
  "Description": "Descriere",
  "Details and status orders": "Comenzile mele",
  "Discount": "Reducere",
  "Display Name": "Numele de afișare",
  "Do you have a coupon code?": "Ai un voucher sau un card cadou?",
  "Done": "Terminat",
  "Download": "Descarcă",
  "Download all": "Descarcă toate",
  "Edit": "Editați | ×",
  "Edit your profile": "Editează profilul",
  "Email": 'Email',
  "Email address": "adresa de e-mail",
  "Empty": "Se pare că încă nu ați adăugat niciun articol în coș.",
  "Empty bag": "Coșul este gol",
  "Enjoy your free account": "Bucurați-vă de aceste avantaje cu contul dvs. gratuit!",
  "Enter promo code": "Introduceți codul promoțional",
  "Feedback": "Feedback-ul dumneavoastră este important pentru noi. Spuneți-ne ce am putea îmbunătăți.",
  "Feel free to edit": "Simțiți-vă liber să editați orice detaliu de mai jos astfel încăt contul dumneavoastră să fie la zi",
  "Filters": "Filtre",
  "Find out more": "Află mai multe",
  "First Name": "Prenume",
  "Forgot Password": "Dacă nu vă amintiți parola, o puteți reseta.",
  "Forgot Password Modal Email": "E-mailul pe care îl utilizați pentru a vă conecta:",
  "forgotPasswordConfirmation": "Mulțumim! Dacă există un cont înregistrat cu adresa de e-mail {0}, veți găsi un mesaj cu un link de resetare a parolei în căsuța dvs. de e-mail.",
  "Forgotten password": "Ai uitat parola?",
  "Go back": "Întoarce-te",
  "Go back shopping": "Întoarce-te la cumpărături",
  "Go back to shop": "Întoarce-te la magazin",
  "Go to checkout": "Mergi la casă",
  "Go to cart": "Întoarce-te la coș",
  "Guarantee": "Garanție",
  "Help": "Ajutor",
  "Help & FAQs": "Ajutor și întrebări frecvente",
  "hide": "ascunde",
  "Home": "Acasă",
  "I agree to": "Sunt de acord să",
  "I confirm subscription": "Confirm abonamentul",
  "I want to create an account": "Sunt de acord cu politica de confidențialitate",
  "Info after order": "Vă puteți conecta la contul dvs. folosind adresa de e-mail și parola definite mai devreme. În contul dvs. puteți edita datele de profil, puteți verifica istoricul tranzacțiilor, puteți edita abonamentul la newsletter.",
  "Instruction1": "Aveți grijă de mine",
  "Instruction2": "Doar aici pentru instrucțiunile de îngrijire?",
  "Instruction3": "Da, așa am crezut și noi",
  "It was not possible to request a new password, please check the entered email address.": "Solicitarea unei parole noi nu a fost posibilă, vă rugăm să verificați adresa de e-mail introdusă.",
  "Item": "Articol",
  "Items": "Articole",
  "Kidswear": "Imbracaminte pentru copii",
  "Last Name": "Nume",
  "Let’s start now – we’ll help you": "Să începem acum – te vom ajuta.",
  "Log into your account": "Conecteaza-te la contul tau",
  "Login": "Continuă",
  "login in to your account": "Conectează-te.",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Se pare că încă nu ați adăugat niciun articol în coș.",
  "Looks like you haven’t added any items to the Wishlist.": "Se pare că nu ați adăugat niciun articol la Lista de dorințe.",
  "Make an order": "Plasare comandă",
  "Manage addresses": "Gestionează toate adresele pe care le dorești (locul de muncă, adresa de acasă...) Astfel nu va trebui să le introduci manual la fiecare comandă.",
  "Manage billing addresses": "Gestionați toate adresele de facturare pe care le aveți (locul de muncă, adresa de acasă...) În acest fel, nu va trebui să le introduceți manual la fiecare comandă.",
  "Manage shipping addresses": "Gestionați toate adresele de expediere pe care le aveți (locul de muncă, adresa de acasă...) În acest fel, nu va trebui să le introduceți manual la fiecare comandă.",
  "Match it with": "Potriviți-l cu",
  "Men fashion": "Moda bărbaților",
  "Menu": "Meniu",
  "My billing and shipping address are the same": "Adresa mea de facturare și expediere sunt aceleași",
  "My Cart": "Coșul meu",
  "No account": "Nu ai un cont?",
  "or": "sau",
  "or fill the details below": "sau completați detaliile de mai jos",
  "Order ID": "Numărul comenzii: ",
  "Order information": "Informații despre comandă",
  "Order No.": "Număr Comandă",
  "Order summary": "Rezumatul Comenzii",
  "Other products you might like": "Alte produse care v-ar putea interesa",
  "Out of stock": "Stoc epuizat",
  "Password": "Parola",
  "Password Changed": "Parola a fost schimbată cu succes. Vă puteți întoarce la pagina de pornire și vă puteți autentifica.",
  "Pay for order": "Plătiți pentru comandă",
  "Payment": "Plată",
  "Payment & Delivery": "Plata și Livrare",
  "Payment Method": "Modalitate de plată",
  "Payment Methods": "Metode de plată",
  "Personal details": "Detalii personale",
  "Please type your current password to change your email address.": "Vă rugăm să introduceți parola curentă pentru a vă schimba adresa de e-mail.",
  "Price": "Preț",
  "Privacy": "Confidențialitate",
  "Privacy Policy": "Politica de confidențialitate",
  "Product": "Produs",
  "Product suggestions": "Sugestii de produse",
  "Products:": "Produse:",
  "Products found": "Produse găsite",
  "Professional account": "Sunt un cumpărător profesionist. Achiziționez un volum mare de produse pentru o companie sau pentru clienți.",
  "Publish in SICAP": "Solicită publicare în SICAP ",
  "Purchase terms": "Condiții de cumpărare",
  "Quality in the details": "Calitate în detalii",
  "Quantity": "Cantitate",
  "Read all reviews": "Citiți toate recenziile",
  "Read and understand": "Am luat la cunoștință",
  "Read reviews": "Citiți recenziile",
  "Recover here": "O poți recupera aici.",
  "Register": "Creează un cont nou",
  "Register today": "Înregistrați-vă astăzi",
  "Remember me": "Ține-mă minte",
  "Remove": "Elimina",
  "Remove Address": "Eliminați adresa",
  "Remove from Wishlist": "Eliminați din lista de dorințe",
  "Repeat Password": "Repetați parola",
  "Reset Password": "Resetează parola",
  "Review my order": "Examinați comanda mea",
  "Same as shipping address": "La fel ca adresa de expediere",
  "Save": "Salvează",
  "Save changes": "Salvează modificările",
  "Save for later": "Economiseste pentru mai tarziu",
  "Save Password": "Salveaza parola",
  "Search": "Căutare",
  "Search for items": "Caută",
  "Search results": "Rezultatele cautarii",
  "Sections that interest you": "Secțiunile care vă interesează",
  "See all results": "Vedeți toate rezultatele",
  "See more": "Vezi mai mult",
  "Select payment method": "Selecteați metoda de plată",
  "Select shipping method": "Selectați metoda de livrare",
  "Send my feedback": "Trimite feedback-ul meu",
  "Set up newsletter": "Abonați-vă la newsletter și vă vom trimite informații despre noile produse și tendințe din secțiunile pe care le-ați selectat în fiecare săptămână.",
  "Share your look": "Împărtășește-ți părerea",
  "Shipping": "Livrare",
  "Shipping address": "Adresă de livrare:",
  "Shipping details": "Detalii de livrare",
  "Shipping method": "Metoda de livrare",
  "Show": "Arată",
  "show more": "Afișați mai multe",
  "Show on page": "Afișați pe pagină",
  "Sign in": "Intră în cont",
  "Sign up": "Creează-ți acum",
  "Size guide": "Ghid marimi",
  "Sign Up for Newsletter": "Înscrieți-vă pentru Newsletter",
  "Sort by": "Filtrează după",
  "Sort: Default": "Predefinit",
  "Sort: Name A-Z": "Nume A-Z",
  "Sort: Name Z-A": "Nume Z-A",
  "Sort: Price from high to low": "Preț de la mare la mic",
  "Sort: Price from low to high": "Preț de la mic la mare",
  "Start shopping": "Începeți cumpărăturile",
  "Status": "Status comandă: ",
  "Subscribe": "Abonați-vă",
  "Subscribe to newsletter": "Abonează-te la Newsletter",
  "subscribeToNewsletterModalContent": "După înscrierea la newsletter, veți primi oferte speciale și mesaje de la noi prin e-mail. Nu vom vinde și nu vom distribui e-mail-ul dvs. unei terțe părți în niciun moment. Vă rugăm să consultați {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Valoare subtotal",
  "Successful placed order": "Ați plasat comanda cu succes. Puteți verifica starea comenzii dvs. utilizând funcția noastră privind starea livrării. Veți primi un e-mail de confirmare a comenzii cu detaliile comenzii și un link pentru a urmări progresul acesteia.",
  "Terms and conditions": "Termeni și condiții",
  "Thank you": "Mulțumim",
  "Thank You Inbox": "Dacă mesajul nu ajunge în căsuța dvs. de e-mail, încercați o altă adresă de e-mail pe care ați folosit-o pentru a vă înregistra.",
  "Total": "Total",
  "Order Total": "Total",
  "Total items": "Total articole",
  "Total price": "Preț final",
  "Update password": "Actualizați parola",
  "Update personal data": "Actualizați datele personale",
  "Use your personal data": "Acordăm o mare importanță problemelor de confidențialitate și ne angajăm să protejăm datele personale ale utilizatorilor noștri. Aflați mai multe despre cum ne pasă și cum folosim datele dumneavoastră cu caracter personal în",
  "User Account": "Contul utilizatorului",
  "View": "Vedere",
  "View details": "Vezi detaliile comenzii ",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Cine suntem",
  "Wishlist": "lista de dorințe",
  "Women fashion": "Moda femeilor",
  "You added {product} to your shopping cart.": "Ați adăugat {produs} în coșul de cumpărături.",
  "You are not authorized, please log in": "Nu sunteți autorizat, vă rugăm să vă autentificați",
  "You can unsubscribe at any time": "Vă puteți dezabona în orice moment",
  "You currently have no orders": "Momentan nu aveți comenzi",
  "You haven\’t searched for items yet": "Nu ați efectuat nicio căutare încă",
  "Your bag is empty": "Coșul dumneavoastră este goală",
  "Your current email address is": "Adresa ta de e-mail actuală este",
  "Your email": "Email-ul tău",
  "The email field must be a valid email": "Câmpul de e-mail trebuie să fie un e-mail valid",
  'You have submitted no reviews': "Nu ați trimis recenzii",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Datele de autentificare sunt incorecte sau contul dvs. este dezactivat temporar. Vă rugăm să încercați din nou mai târziu.",
  "A customer with the same email address already exists in an associated website.": "Un client cu aceeași adresă de e-mail există deja pe un site web asociat.",
  "Invalid email": "E-mail invalid",
  "SUMMER COLLECTION {year}": "COLECȚIA DE VARĂ {an}",
  "Colorful summer dresses are already in store": "Rochiile colorate de vară sunt deja în magazin",
  "Learn more": "Află mai multe",
  "Dresses": "Rochii",
  "Cocktail & Party": "Petrecere cu cocktail",
  "Linen Dresses": "Rochii de in",
  "T-Shirts": "Tricouri",
  "The office life": "Viața de birou",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Găsiți rochii de cocktail și rochii de petrecere uimitoare pentru femei. Ieși în evidență în rochii de cocktail din dantelă și metal de la toate mărcile tale preferate.",
  "Shop now": "Cumpără acum",
  "The Office Life": "Viața de birou",
  "Summer Sandals": "Sandale de vară",
  "Eco Sandals": "Sandale Eco",
  "Subscribe to Newsletters": "Abonați-vă la newsletter",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Fiți la curent cu vânzările și evenimentele viitoare. Primiți cadouri și oferte speciale",
  "Fashion to take away": "Moda de luat",
  "Download our application to your mobile": "Descărcați aplicația noastră pe mobil",
  "Share Your Look": "Împărtășește-ți imaginea",
  "My Account": "Contul meu",
  "My profile": "Profilul meu",
  "Personal Details": "Detalii personale",
  "Addresses details": "Detalii adrese",
  "My newsletter": "Buletinul meu informativ",
  "My reviews": "Recenziile mele",
  "Order history": "Istoric comenzi",
  "Order details": "Detaliile comenzii",
  "My wishlist": "Lista mea de dorințe",
  "Password change": "Schimbarea parolei",
  "Personal data": "Date personale",
  "Your e-mail": "Adresa de e-mail",
  "Current Password": "Parola actuală",
  "You are not authorized, please log in.": "Nu sunteți autorizat, vă rugăm să vă autentificați.",
  "Go To Product": "Accesați Produsul",
  "Change to list view": "Vizualizare - listă",
  "Change to grid view": "Vizualizare - grilă",
  "Returns": "Retururi",
  "My orders": "Comenzile mele",
  "Add the address": "Adăugați adresa",
  "Set as default shipping": "Setați ca și adresă de livrare implicită",
  "Set as default billing": "Setați ca și adresă de facturare implicită",
  "House/Apartment number": "Numărul casei/apartamentului",
  "Street Name": "Numele străzii",
  "City": "Oraș",
  "State/Province": "Județ",
  "Zip-code": "Cod poștal",
  "Country": "Țară",
  "Phone number": "Număr de telefon",
  "Please select a country first": "Vă rugăm să selectați mai întâi o țară",
  "This field is required": "Acest câmp este obligatoriu",
  "The field should have at least 2 characters": "Câmpul trebuie să aibă cel puțin 2 caractere",
  "The field should have at least 4 characters": "Câmpul trebuie să aibă cel puțin 4 caractere",
  "The field should have at least 8 characters": "Câmpul trebuie să aibă cel puțin 8 caractere",
  "New Password": "Parolă Nouă",
  "New Products": "Produse noi",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Nu există metode de expediere disponibile pentru această țară. Ne pare rău, vă rugăm să încercați cu altă țară.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "A apărut o eroare la încercarea de a prelua metodele de expediere. Ne pare rău, vă rugăm să încercați cu detalii diferite de livrare.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "A apărut o eroare la încercarea de a selecta această metodă de expediere. Ne pare rău, vă rugăm să încercați cu o altă metodă de expediere.",
  "We can't find products matching the selection.": "Nu putem găsi produse care să se potrivească selecției.",
  'Page not found': '404 - pagină inexistentă',
  'Page not available': 'Pagina pe care o cauți nu este disponibilă. Încercați să căutați din nou sau folosiți butonul de întoarcere de mai jos.',
  'Back to Home page': 'Inapoi la prima pagină',
  'An error occurred': 'A aparut o eroare',
  "AllProductsFromCategory": "Toate {categoryName}",
  "Show more": "Afișează mai multe",
  "Show less": "Afișează mai puține",
  "Yes": "da",
  "No": "Nu",
  "Apply filters": "Filtrează",
  "The coupon code isn't valid. Verify the code and try again.": "Codul de cupon nu este valid. Verificați codul și încercați din nou.",
  "From": "Din",
  "To": "La",
  "Your customization": "Personalizarea dvs",
  "Passwords don't match": "Parolele nu sunt identice",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)": "Parola trebuie să aibă cel puțin 8 caractere și trebuie să conțină cel puțin: 1 literă mare și una mică, 1 număr sau un caracter special (de ex. , . _ & ? etc)",
  "Show all products": "La lista de preferințe",
  "Select previously saved address": "Selectați adresa salvată anterior",
  "Enter different address": "Introduceți o altă adresă",
  "You must confirm your account. Please check your email for the confirmation link.": "Trebuie să vă confirmați contul. Vă rugăm să vă verificați e-mailul pentru linkul de confirmare.",
  "Change Store": "Schimbați magazinul",
  "Choose Currency": "Alege moneda",
  "Add a review": "Adaugă o recenzie",
  "Add to cart": "Adaugă in coş",
  "Title": "Titlu",
  "Name": "Nume",
  "Review": "Revizuire",
  "Add review": "Adaugă recenzie",
  "Are you sure you would like to remove this item from the shopping cart?": "Sigur doriți să eliminați acest articol din coșul de cumpărături?",
  "Your cart is empty": "Coșul tău este gol",
  "Are you sure?": "Esti sigur?",
  "{0} has been successfully removed from your cart": "{0} a fost eliminat cu succes din coș",
  "Amount": "Cantitate",
  "Thank you for your order!": "Vă mulțumim pentru comanda dumneavoastră!",
  "Your Purchase": "Achiziția ta",
  "Primary contacts for any questions": "Contacte primare pentru orice întrebare",
  "Your Account": "Contul tău",
  "What can we improve": "Ce putem îmbunătăți",
  "Payment date": "Data plății",
  "The user password was changed successfully updated!": "Parola utilizatorului a fost schimbată, actualizată cu succes!",
  "The user account data was successfully updated!": "Datele contului de utilizator au fost actualizate cu succes!",
  "You submitted your review for moderation.": "Ți-ai trimis recenzia pentru moderare.",
  "Starting at": "Începând la",
};
