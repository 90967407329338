
export default `
query getReturnables{
  customer {
  returnRequests{
            total_count
            items {
                date
                id
                status
                total_item_count
                total_qty_returned
                grand_total {
                    currency
                    value
                }
                returned_items{
                    total_count
                          items {
                        comment
                        id
                        order_item_id
                        order_number
                        order_status
                        product_image
                        product_name
                        product_sku
                        product_small_image
                        product_thumbnail
                        product_type
                        product_url_key
                        quantity_ordered
                        quantity_returned
                        reason
                        request_id
                         product_sale_price {
                            currency
                            value
                        }
                        total {
                            discount_amount {
                                currency
                                value
                            }
                            original_price {
                                currency
                                value
                            }
                            price {
                                currency
                                value
                            }
                            row_total {
                                currency
                                value
                            }
                            tax_amount {
                                currency
                                value
                            }
weee_tax_applied {
                                amount
                                amount_incl_tax
                                base_amount
                                base_amount_incl_tax
                                base_row_amount
                                base_row_amount_incl_tax
                                row_amount
                                row_amount_incl_tax
                                title
                            }
                            weee_tax_applied_amount {
                                currency
                                value
                            }
                            weee_tax_applied_row_amount {
                                currency
                                value
                            }




}

}
                    }

                }

}
    returnableItems

    {
      items {
      order_item_id
      order_number
      order_status
      product_sku
      product_name
      product_url_key
      product_type
      product_thumbnail
      product_image
      product_small_image
      status
      discounts {
        amount {
          currency
          value
        }
        label
      }
      product_sale_price {
        currency
        value
      }
      selected_options {
        label
        value
      }
      entered_options {
        label
        value
      }
      quantity_ordered
      quantity_returnable
      reason
    }
      total_count
    }
  }
}
   `;
