
export default `query Customer {
  customer {
    addresses {
      city
      company
      country_code
      country_id
      customer_id
      default_billing
      default_shipping
      fax
      firstname
      id
      lastname
      middlename
      postcode
      prefix
      region_id
      street
      suffix
      telephone
      vat_id
      registration_number
    }
  }
}
    `;
