import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import useApi from '~/composables/useApi';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import {
  createProductAttributeFilterInput,
  createProductAttributeFilterInputSpecial
} from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { Products } from '~/modules/GraphQL/types';
import getFacetDataQuery from './getFacetData.gql';
import getPromotionalByDiscount from './getPromotionalProducts.gql';
import getAllProductsSku from './getAllProductsSku.gql';
import getFacetDataQueryMinimal from './getFacetDataMinimal.gql';
import getCompareDataQuery from './getCompareFacetData.gql';
import type {
  UseFacetInterface, UseFacetErrors, UseFacetSearchResult, FacetSearchParams,
} from './useFacet';
import {ProductList} from "~/modules/catalog/product/composables/useProduct";
import GetProductFilterByCategoryQuery from "~/modules/catalog/category/components/filters/command/getProductFilterByCategory.gql";

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet(): UseFacetInterface {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const result2 = ref<UseFacetSearchResult>({ data: null, input: null });
  const result3 = ref<UseFacetSearchResult>({ data: null, input: null });
  const result_only_sku = ref<UseFacetSearchResult>({data: null, input: null});
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const defaultItemsPerPage = 32;
  const search = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize: params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };

      const { data } = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  const promotionalProducts = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: {},
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };

      const { data } = await query<{ products: Products }>(getPromotionalByDiscount, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  const searchSpecial = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);
    // params.category_uid = ["MzcwNw==", "MzcxOA=="];
    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInputSpecial(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };
      const { data } = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        aggregations: products?.aggregations,
        items: products?.items ?? [],
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }

  };
  const search2 = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);
    let data = null;
    result2.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: {},
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };

      const {data}  = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
      const products =await data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result2.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      // const { data } = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
      // const products = data?.products ?? null;
      // Logger.debug('[Result]:', { products });
      //
      // result.value.data = {
      //   items: products?.items ?? [],
      //   total: products?.total_count,
      //   availableSortingOptions: sortingOptions,
      //   perPageOptions,
      //   itemsPerPage: pageSize,
      // };



      error.value.search = null;
      return result2;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };
  const search3 = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);
    // params.category_uid = ["MzcwNw==", "MzcxOA=="];
    result3.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInputSpecial(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };
      const { data } = await query<{ products: Products }>(getFacetDataQueryMinimal, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result3.value.data = {

        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }

  };
  const filterCompare = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);
    // params.category_uid = ["MzcwNw==", "MzcxOA=="];
    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };
      const { data } = await query<{ products: Products }>(getCompareDataQuery, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };
  const search_only_sku = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);
    result_only_sku.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : 9999;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInputSpecial(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };
      const { data } = await query<{ products: Products }>(getAllProductsSku, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result_only_sku.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations,
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };
  const execute = async (params?: FacetSearchParams) => {
    const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

    const productSearchParams: GetProductSearchParams = {
      pageSize,
      search: params.term ? params.term : '',
      filter: createProductAttributeFilterInput(params),
      sort: createProductAttributeSortInput(params.sort || ''),
      currentPage: params.page,
    };
    const { data } = await query<{ products: Products }>(GetProductFilterByCategoryQuery, productSearchParams);

    return data?.products?.aggregations ?? [];
  };
  const executeForSearch = async (params?: FacetSearchParams) => {
    const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

    const productSearchParams: GetProductSearchParams = {
      pageSize,
      search: params.term ? params.term : '',
      filter: createProductAttributeFilterInputSpecial(params),
      sort: createProductAttributeSortInput(params.sort || ''),
      currentPage: params.page,
    };
    const { data } = await query<{ products: Products }>(GetProductFilterByCategoryQuery, productSearchParams);

    return data?.products?.aggregations ?? [];
  };

  return {
    execute,
    executeForSearch,
    search,
    search2,
    search3,
    searchSpecial,
    filterCompare,
    search_only_sku,
    promotionalProducts,
    result_only_sku,
    result,
    result3,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
