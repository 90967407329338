import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aa208184 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _43a2db64 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _bc3a7ea0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _32a5f704 = () => interopDefault(import('../modules/checkout/pages/Checkout/FailedPayment.vue' /* webpackChunkName: "" */))
const _409212bb = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _4ba0e86e = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _78855120 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _a1f9afd2 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _7bccec90 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _3315a2cc = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _2ac2207d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6c0ee0eb = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _b799b4ce = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _22fb54a7 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _493be36b = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4514fab6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _c33b1baa = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _b13137b4 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _4fc8fedf = () => interopDefault(import('../modules/customer/pages/MyAccount/Returns.vue' /* webpackChunkName: "" */))
const _477e1c12 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _883b447a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _fd22f030 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _05e947dd = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _4bf2c8ec = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _67ac40a8 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _344e9b10 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _013eaee9 = () => interopDefault(import('../pages/manufacturers.vue' /* webpackChunkName: "pages/manufacturers" */))
const _903d5350 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _74803868 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _5e35b86d = () => interopDefault(import('../modules/catalog/pages/searchCategory.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _aa208184,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _43a2db64,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _bc3a7ea0,
      name: "billing___default"
    }, {
      path: "failed-payment",
      component: _32a5f704,
      name: "failed-payment___default"
    }, {
      path: "payment",
      component: _409212bb,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _4ba0e86e,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _78855120,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _a1f9afd2,
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _7bccec90,
    name: "Cms___default"
  }, {
    path: "/compare",
    component: _3315a2cc,
    name: "compare___default"
  }, {
    path: "/customer",
    component: _2ac2207d,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _6c0ee0eb,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _b799b4ce,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _22fb54a7,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _493be36b,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _4514fab6,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _c33b1baa,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "reset-password",
      component: _b13137b4,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "returns",
      component: _4fc8fedf,
      meta: {"titleLabel":"Returns"},
      name: "customer-returns___default"
    }, {
      path: "addresses-details/create",
      component: _477e1c12,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _883b447a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _fd22f030,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Faq",
    component: _05e947dd,
    name: "Faq___default"
  }, {
    path: "/forgot-password",
    component: _4bf2c8ec,
    name: "forgot-password___default"
  }, {
    path: "/Home",
    component: _67ac40a8,
    name: "Home___default"
  }, {
    path: "/login",
    component: _344e9b10,
    name: "login___default"
  }, {
    path: "/manufacturers",
    component: _013eaee9,
    name: "manufacturers___default"
  }, {
    path: "/Page",
    component: _903d5350,
    name: "Page___default"
  }, {
    path: "/register",
    component: _74803868,
    name: "register___default"
  }, {
    path: "/search/:searchTerm",
    component: _5e35b86d,
    name: "SearchCategoryPage___default"
  }, {
    path: "/",
    component: _67ac40a8,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _903d5350,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
