import type { RawLocation } from 'vue-router';

import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';

type LinkGroup = { title: string, items: LinkGroupItem[] };
type LinkGroupItem = { id: string, label: string, link?: RawLocation, icon?: string };

export const useSidebarLinkGroups = () => {
  const { localeRoute } = useContext();
  const { logout } = useUser();
  const { clear } = useCart();
  const router = useRouter();

  const sidebarLinkGroups : LinkGroup[] = [
    {
      title: 'Personal details',
      items: [
        // {
        //   id: 'dashboard',
        //   label: 'Panou de control',
        //   link: { name: 'dashboard' },
        // },
        {
          id: 'my-profile',
          label: 'Editeaza profilul',
          link: { name: 'customer-my-profile' },
          icon: '/icons/tabler-icons/edit-profile.svg',
        },
        {
          id: 'order-history',
          label: 'Comenzile mele',
          link: { name: 'customer-order-history' },
          icon: '/icons/tabler-icons/orders.svg',
        },
        {
          id: 'returns',
          label: 'Retururi',
          link: { name: 'customer-returns' },
          icon: '/icons/tabler-icons/return.svg',
        },
        // {
        //   id: 'address-details',
        //   label: 'Adresele mele',
        //   link: { name: 'customer-addresses-details' },
        // },
        {
          id: 'guarantees',
          label: 'Garantiile mele',
          link: { name: 'customer-returns' },
          icon: '/icons/tabler-icons/shield.svg',
        },
        {
          id: 'reviews',
          label: 'Recenziile mele',
          link: { name: 'customer-returns' },
          icon: '/icons/tabler-icons/star.svg',
        },
        {
          id: 'billing-details',
          label: 'Date de facturare',
          link: { name: 'customer-returns' },
          icon: '/icons/tabler-icons/billing.svg',
        },
        {
          id: 'settings',
          label: 'Setari de siguranta',
          link: { name: 'customer-returns' },
          icon: '/icons/tabler-icons/settings.svg',
        },
        // {
        //   id: 'my-wishlist',
        //   label: 'Lista de dorinte',
        //   link: { name: 'customer-my-wishlist' },
        // },
        {
          id: 'log-out',
          label: 'Log out',
          icon: '/icons/tabler-icons/logout.svg',
        },
        // {
        //   id: 'my-newsletter',
        //   label: 'My newsletter',
        //   link: { name: 'customer-my-newsletter' },
        // },
        // {
        //   id: 'my-reviews',
        //   label: 'My reviews',
        //   link: { name: 'customer-my-reviews' },
        // },
      ],
    },
    // {
    //   title: 'Order details',
    //   items: [
    //     {
    //       id: 'order-history',
    //       label: 'Order history',
    //       link: { name: 'customer-order-history' },
    //     },
    //     {
    //       id: 'my-reviews',
    //       label: 'My reviews',
    //       link: { name: 'customer-my-reviews' },
    //     },
    //     {
    //       id: 'log-out',
    //       label: 'Log out',
    //     },
    //   ],
    // },
  ];

  const logoutUser = async () => {
    await logout({});
    await clear({});
    await router.push(localeRoute({ name: 'home' }));
  };

  return { sidebarLinkGroups, logoutUser };
};
