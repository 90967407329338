/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String, $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
      categories {
                id
                level
                name
                path
                position
                priority
                uid
                url_key
                url_path
                url_suffix
                description
                image
            }


      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
