/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getPromotionalByDiscount{
    products( filter: { promotions: { eq: "1" }, sort_by_percent_off: { eq: "DESC" } }
    pageSize: 32
    currentPage: 1
     ) {
      items {
       __typename
       id
       sku
        name
      image {
        url (display_area:"product_page_image_medium")
        label
        position
      }
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }

      }
      page_info {
       current_page
       page_size
       total_pages
      }
      total_count
     }

  }
`;
