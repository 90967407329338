

























import { SfImage } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage },
  setup() {
    const { config } = useConfig();

    const logoSrc = computed(() => {
      const baseMediaUrl = '/homepage';
      const logo = 'tg_image_2052363253.jpeg';

      return baseMediaUrl && logo ? `${baseMediaUrl}/${logo}` : '';
    });

    const logoWidth = computed(
      () => config.value.logo_width || '492',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '80',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || '',
    );

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
    };
  },
});
