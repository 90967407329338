export default `

query getWishlist{
  customer {
    wishlists {
    id
    items_count
    sharing_code
      items_v2 {
      page_info{
      current_page
      page_size
      total_pages
      }
        items {
        id
        quantity
        description
        added_at
          product {
          stock_status
          only_x_left_in_stock
          rating_summary
          categories{
          uid
          name
          url_suffix
          url_path

          }
          url_key
          url_rewrites{
          url
          }
            name
            sku
            price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
            thumbnail {
               url ( display_area: "wishlist_thumbnail" )
              label
              position
            }
            small_image {
\t\t\t\t\t\t\turl(display_area: "wishlist_thumbnail")
\t\t\t\t\t\t}
media_gallery {
\t\t\t\t\t\t\tlabel
\t\t\t\t\t\t\turl(display_area: "wishlist_thumbnail")
\t\t\t\t\t\t}
\t\t\t\t\t\timage {
\t\t\t\t\t\t\turl(display_area: "wishlist_thumbnail")
\t\t\t\t\t\t\tlabel
\t\t\t\t\t\t\tposition
\t\t\t\t\t\t}
          }
        }
      }
    }
  }
}
  `;
