








































































import {
  defineComponent, onMounted, PropType, ref, useContext,computed
} from '@nuxtjs/composition-api';

import {
  SfMegaMenu, SfList, SfImage, SfMenuItem, SfBanner, SfButton, SfModal,
} from '@storefront-ui/vue';

import SvgImage from '~/components/General/SvgImage.vue';
import { CategoryTree } from '~/modules/GraphQL/types';
import {useUiHelpers, useUiState} from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import {useCategoryStore} from "~/modules/catalog/category/stores/category";

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationSubcategories: () => import('~/components/Header/Navigation/HeaderNavigationSubcategories.vue'),
    HeaderNavigationItem,
    SfMegaMenu,
    SfList,
    SfImage,
    SfMenuItem,
    SfBanner,
    SfButton,
    SfModal,
    SvgImage,
    // useDisclosure,
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const { getCatLink } = useUiHelpers();

    const currentCategory = ref<CategoryTree | null>(null);

    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    const { app } = useContext();
    let lvl0CatFocusIdx = 0;
    let focusedElement : HTMLElement | null = null;
    const menuImages = [
      { url: '/homepage/img.png', alt: 'Menu Image 1' },
      { url: '/path/to/image2.jpg', alt: 'Menu Image 2' },
      // Add more images as needed
    ];
    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };
    const blaaa = {children: props.categoryTree, include_in_menu: 1}
    const newMenu = { 'Acasa':{},'Produse':blaaa, 'Promotii':{},'Outlet':{}};
    const { isDesktop } = app.$device;

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };
    const handleClick = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;

      if (focusedElement.getAttribute('href') === '/default/undefined') {

        event.preventDefault();
      }

      // lvl0CatFocusIdx = Number(event.target.dataset.index);
      // hasFocus.value = true;
    };
    const ReplaceUndefinedLink = () => {

    };
    const groupedCategories = computed(() => {
      const flatCategories = props.categoryTree.flatMap(category => category);
      const groups = [];
      for (let i = 0; i < flatCategories.length; i += 10) {
        groups.push(flatCategories.slice(i, i + 10));
      }
      return groups;
    });
    onMounted(() => {
      ReplaceUndefinedLink();
    });
    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };

    return {
      getCatLink,
      menuImages,
      setCurrentCategory,
      currentCategory,
      groupedCategories,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0CatRefs,
      navRight,
      navLeft,
      hasFocus,
      onMouseEnter,
      newMenu,
      isMobileMenuOpen,
      toggleMobileMenu,
      isDesktop,
      handleClick,
      ReplaceUndefinedLink,
    };
  },
});
