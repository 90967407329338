
export default `
query getCustomerCart {
customerCart{
id
email
is_virtual
prices{
subtotal_excluding_tax{
value
}
subtotal_including_tax{
value
}
applied_taxes{
amount{
value}
label
}

grand_total{
value}
}
total_quantity
shipping_addresses{
firstname
lastname
country{
code
label
}
street
city
company
region{
code
region_id
label
}
postcode
telephone
}

billing_address{
firstname
lastname
country{
code
label
}
street
city
company
region{
code
region_id
label
}
postcode
telephone
}

    items {
    uid
    quantity
    prices {
          row_total {
            value

          },
          row_total_including_tax {
            value

          }
          total_item_discount{
            value
          }

        }
      product {

        uid
        name
        sku
        stock_status
        only_x_left_in_stock
        rating_summary
        url_key
        url_rewrites{
        url
        }
        categories{
              uid
              name
              url_suffix
              url_path
              breadcrumbs{
               category_name
               category_url_path
               }
            },
        price_range{
        maximum_price{
        final_price{
        currency
        value
        }
        regular_price{
        currency
        value
        }
        }
        minimum_price{
        final_price{
        currency
        value
        }
        regular_price{
        currency
        value
        }
        }
        }
        media_gallery {
          label
          url(display_area: "cart_page_product_thumbnail")
        }
        image {
          url(display_area: "cart_page_product_thumbnail")
          label
          position
        }
        thumbnail {
          url(display_area: "cart_page_product_thumbnail")
          position
        }
        small_image {
          url(display_area: "cart_page_product_thumbnail")
        }
      }
    }
  }
}
  `;
