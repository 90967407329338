/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {

      items {
        uid
         __typename
              inventory_sources {
      source_name
      source_city
      source_code
      source_quantity
      source_standard_delivery_time
}
  vsbridge_description{
      html
      }
backorders_enabled
         other_etim_attributes
    other_logistics_attributes
    other_attributes
              custom_attributes {
                attribute_code
                attribute_label
                value
                text_value
            }
        sku
         regular_price_without_tax {
        value,
        currency
        }
        final_price_without_tax {
        value,
        currency
        }
        is_promoted
        name
        stock_status
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
